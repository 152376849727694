<template>
  <div>
    <div class="row">
      <retry-notification
        :show="error"
        @retry="updateData()"
      />
    </div>
    <div class="row">
      <div class="flex xs12 md6">
        <va-card :title="$t('reports.tabs.data.title')">
          <actions
            slot="actions"
            crud-links="reports"
            controller="Proyections"
            :actions="actions"
            :action-data="{id: $route.params.id}"
          />
          <data-form
            :report="report"
            :loading="loading"
            @submit="submit"
            @approval="approve"
          />
        </va-card>
      </div>
      <div class="flex xs12 md6">
        <va-card :title="$t('reports.tabs.title')">
          <tabs-container :tabs="tabs">
            <template v-slot:presentations>
              <presentations-table
                :edit="true"
                :report="report"
                :loading="loading"
                @submit="submit"
              />
              <text-list
                v-show="!minPresentations"
                :title="$t(`reports.wizard.form.reason`)"
              >
                {{ report.observations }}
              </text-list>
            </template>
            <template v-if="report.preaching_points" v-slot:type>
              <preaching-points
                :loading="loading"
                :edit="true"
                :report-id="report.id"
                :team="report.team_id"
                :district-id="report.district_id"
                :table-data="report.preaching_points"
              />
            </template>
          </tabs-container>
        </va-card>
      </div>
    </div>
  </div>
</template>

<script>
const PresentationsTable = () => import(/* webpackPrefetch: true */ './presentations/Table')
const PreachingPoints = () => import(/* webpackPrefetch: true */ './PreachingPoints')
const TabsContainer = () => import(/* webpackPrefetch: true */ '@/components/extras/TabsContainer')
const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')
const DataForm = () => import(/* webpackPrefetch: true */ './Form')
const TextList = () => import(/* webpackPrefetch: true */ '@/components/extras/Text/TextList')

export default {
  name: 'reports-edit',
  components: {
    DataForm,
    Actions,
    PresentationsTable,
    TabsContainer,
    PreachingPoints,
    TextList,
  },
  data () {
    return {
      error: false,
      loading: false,
      actions: ['index', 'new', 'view', 'delete'],
      report: {},
      approveReport: {
        status: false,
        value: 0,
      },
    }
  },
  created () {
    this.updateData()
  },
  computed: {
    tabs () {
      const t = [{ title: this.$t('reports.tabs.presentations.title'), name: 'presentations' }]
      if (this.report.type !== null) {
        t.push({ title: this.$t('reports.tabs.type.title'), name: 'type' })
      }

      return t
    },
    minPresentations () {
      return this.report.observations
    },
  },
  methods: {
    // FIXME
    routeBuilder (id) {
      return `reports/districts/${id}`
    },
    async updateData () {
      this.loading = true
      this.error = false

      let u = false
      const reportId = this.$route.params.id
      try {
        u = await this.$http.get(this.routeBuilder(reportId))
      } catch (err) {
        // console.log('Error fetching report data', err)
        this.error = true
        this.loading = false
        return
      }

      this.loading = false
      this.report = u.data.data
    },
    async submit (report) {
      this.loading = true
      if (!this.approveReport.status) {
        try {
          await this.$http.put(this.routeBuilder(report.report.id), report.report)
        } catch (error) {
          // console.log('Error updating data', error)
          this.loading = false
          return
        }
      }

      if (this.approveReport.status) {
        try {
          await this.$http.put(this.routeBuilder(report.id), report)
        } catch (error) {
          // console.log('Error updating data', error)
          this.loading = false
          return
        }
      }

      if (report.users && report.users._ids) {
        return this.reportHistory(report)
      }

      if (this.approveReport.status) {
        return this.submitApproval()
      }

      this.loading = false
      this.showToast(this.$t('notifications.alerts.success'), {
        icon: 'fa-check',
        position: 'top-right',
      })
    },
    async submitApproval () {
      this.loading = true
      const reportId = this.$route.params.id
      const approveStatus = this.approveReport.value.approve === 1 ? 'approved' : 'rejected'
      const approveType = this.approveReport.value.type

      let response = false
      try {
        response = await this.$http.post(`reports/${approveType}/approve/${reportId}`, this.approveReport.value)
      } catch (error) {
        // console.log('Approve error', error)
        this.loading = false
        return
      }

      this.showToast(this.$t('reports.notifications.' + approveStatus), {
        icon: approveStatus === 'approved' ? 'fa-check' : 'fa-times',
        position: 'top-right',
      })
      this.loading = false
      this.$router.push({ name: 'reportsIndex' })
      return response
    },
    approve (data) {
      this.approveReport.status = true
      this.approveReport.value = data.approve
      this.submit(data.report)
    },
    async reportHistory (report) {
      const data = {
        originals: [],
        currents: report.users._ids,
      }

      this.report.users.forEach(m => {
        data.originals.push(m.id)
      })

      let response = null
      try {
        response = await this.$http.post('proyections/history/' + this.report.id, data)
      } catch (e) {
        // console.log('History update error', e)
        this.loading = false
      }

      this.loading = false
      return response
    },
  },
}
</script>
